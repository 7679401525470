<template>
  <table style="width: 100%" class="population-insights-table">
    <tr>
      <th :style="colWidth" v-for="(incite, index) in insights" :key="index">
        <span class="calloutLabel color-secondary">{{ incite.label }}</span>
      </th>
    </tr>
    <tr>
      <td v-for="(incite, index) in insights" :key="index">
        <span class="callout fw-300 color-secondary">
          <animated-number
            :data-test="incite.label"
            :number="
              incite.value | formatNumber({ format: 'precision', places: 0 })
            "
            :numberFormat="{ format: incite.format ? incite.format : 'commas' }"
          ></animated-number
          ></span>
      </td>
    </tr>
  </table>
</template>

<script>
import AnimatedNumber from '@/components/base/animated_number.vue';
export default {
  name: 'LengthOfStayInsightsTable',
  props: {
    insights: {
      type: Array
    }
  },
  components: {
    AnimatedNumber
  },
  computed: {
    colWidth() {
      return 'width: ' + 100 / this.insights.length + '%;';
    }
  }
};
</script>

<style lang="scss">
.population-insights-table {
  th {
    text-align: left;
  }
}
</style>
